import React from 'react';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { Modal, useStyles2 } from '@grafana/ui';

const shortcuts = {
  Global: [
    { keys: ['g', 'h'], description: '返回到仪表盘' },
    { keys: ['g', 'p'], description: '转到个人资料' },
    { keys: ['s', 'o'], description: '开放搜索' },
    { keys: ['esc'], description: '退出编辑/设置视图' },
  ],
  Dashboard: [
    { keys: ['mod+s'], description: '保存仪表盘' },
    { keys: ['d', 'r'], description: '刷新所有面板' },
    { keys: ['d', 's'], description: '仪表盘设置' },
    { keys: ['d', 'v'], description: '在活动/查看模式下切换' },
    { keys: ['d', 'k'], description: '切换信息亭模式（隐藏顶部导航）' },
    { keys: ['d', 'E'], description: '展开所有行' },
    { keys: ['d', 'C'], description: '折叠所有行' },
    { keys: ['d', 'a'], description: '切换自动装配面板（实验性功能）' },
    { keys: ['mod+o'], description: '切换共享图形十字线' },
    { keys: ['d', 'l'], description: '切换所有面板图例' },
  ],
  'Focused Panel': [
    { keys: ['e'], description: '切换面板编辑视图' },
    { keys: ['v'], description: '切换面板全屏视图' },
    { keys: ['p', 's'], description: '开放式面板共享模式' },
    { keys: ['p', 'd'], description: '复制面板' },
    { keys: ['p', 'r'], description: '删除面板' },
    { keys: ['p', 'l'], description: '切换面板图例' },
  ],
  'Time Range': [
    { keys: ['t', 'z'], description: '缩小时间范围' },
    {
      keys: ['t', '←'],
      description: '向后移动时间范围',
    },
    {
      keys: ['t', '→'],
      description: '向前移动时间范围',
    },
  ],
};

export interface HelpModalProps {
  onDismiss: () => void;
}

export const HelpModal = ({ onDismiss }: HelpModalProps): JSX.Element => {
  const styles = useStyles2(getStyles);
  return (
    <Modal title="快捷键" isOpen onDismiss={onDismiss} onClickBackdrop={onDismiss}>
      <div className={styles.titleDescription}>
        <span className={styles.shortcutTableKey}>mod</span> =<span> 在windows或linux上按CTRL键，在Mac上按CMD键</span>
      </div>
      <div className={styles.categories}>
        {Object.entries(shortcuts).map(([category, shortcuts], i) => (
          <div className={styles.shortcutCategory} key={i}>
            <table className={styles.shortcutTable}>
              <tbody>
                <tr>
                  <th className={styles.shortcutTableCategoryHeader} colSpan={2}>
                    {category}
                  </th>
                </tr>
                {shortcuts.map((shortcut, j) => (
                  <tr key={`${i}-${j}`}>
                    <td className={styles.shortcutTableKeys}>
                      {shortcut.keys.map((key, k) => (
                        <span className={styles.shortcutTableKey} key={`${i}-${j}-${k}`}>
                          {key}
                        </span>
                      ))}
                    </td>
                    <td className={styles.shortcutTableDescription}>{shortcut.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </Modal>
  );
};

function getStyles(theme: GrafanaTheme2) {
  return {
    titleDescription: css`
      font-size: ${theme.typography.bodySmall.fontSize};
      font-weight: ${theme.typography.bodySmall.fontWeight};
      color: ${theme.colors.text.disabled};
      padding-bottom: ${theme.spacing(2)};
    `,
    categories: css`
      font-size: ${theme.typography.bodySmall.fontSize};
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: flex-start;
    `,
    shortcutCategory: css`
      width: 50%;
      font-size: ${theme.typography.bodySmall.fontSize};
    `,
    shortcutTable: css`
      margin-bottom: ${theme.spacing(2)};
    `,
    shortcutTableCategoryHeader: css`
      font-weight: normal;
      font-size: ${theme.typography.h6.fontSize};
      text-align: left;
    `,
    shortcutTableDescription: css`
      text-align: left;
      color: ${theme.colors.text.disabled};
      width: 99%;
      padding: ${theme.spacing(1, 2)};
    `,
    shortcutTableKeys: css`
      white-space: nowrap;
      width: 1%;
      text-align: right;
      color: ${theme.colors.text.primary};
    `,
    shortcutTableKey: css`
      display: inline-block;
      text-align: center;
      margin-right: ${theme.spacing(0.5)};
      padding: 3px 5px;
      font: 11px Consolas, 'Liberation Mono', Menlo, Courier, monospace;
      line-height: 10px;
      vertical-align: middle;
      border: solid 1px ${theme.colors.border.medium};
      border-radius: ${theme.shape.borderRadius(3)};
      color: ${theme.colors.text.primary};
      background-color: ${theme.colors.background.secondary};
    `,
  };
}
