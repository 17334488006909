import React, { FC, MouseEvent, PureComponent } from 'react';
import { css } from '@emotion/css';
import { Icon, IconButton, useStyles } from '@grafana/ui';
import { selectors } from '@grafana/e2e-selectors';
import { GrafanaTheme } from '@grafana/data';

import EmptyListCTA from '../../../core/components/EmptyListCTA/EmptyListCTA';
import { QueryVariableModel, VariableModel } from '../types';
import { toVariableIdentifier, VariableIdentifier } from '../state/types';
import { DashboardModel } from '../../dashboard/state';
import { getVariableUsages, UsagesToNetwork, VariableUsageTree } from '../inspect/utils';
import { isAdHoc } from '../guard';
import { VariableUsagesButton } from '../inspect/VariableUsagesButton';

export interface Props {
  variables: VariableModel[];
  dashboard: DashboardModel | null;
  usages: VariableUsageTree[];
  usagesNetwork: UsagesToNetwork[];
  onAddClick: (event: MouseEvent) => void;
  onEditClick: (identifier: VariableIdentifier) => void;
  onChangeVariableOrder: (identifier: VariableIdentifier, fromIndex: number, toIndex: number) => void;
  onDuplicateVariable: (identifier: VariableIdentifier) => void;
  onRemoveVariable: (identifier: VariableIdentifier) => void;
}

enum MoveType {
  down = 1,
  up = -1,
}

export class VariableEditorList extends PureComponent<Props> {
  onEditClick = (event: MouseEvent, identifier: VariableIdentifier) => {
    event.preventDefault();
    this.props.onEditClick(identifier);
  };

  onChangeVariableOrder = (event: MouseEvent, variable: VariableModel, moveType: MoveType) => {
    event.preventDefault();
    this.props.onChangeVariableOrder(toVariableIdentifier(variable), variable.index, variable.index + moveType);
  };

  onDuplicateVariable = (event: MouseEvent, identifier: VariableIdentifier) => {
    event.preventDefault();
    this.props.onDuplicateVariable(identifier);
  };

  onRemoveVariable = (event: MouseEvent, identifier: VariableIdentifier) => {
    event.preventDefault();
    this.props.onRemoveVariable(identifier);
  };

  render() {
    return (
      <div>
        <div>
          {this.props.variables.length === 0 && (
            <div>
              <EmptyListCTA
                title="目前还没有变量"
                buttonIcon="calculator-alt"
                buttonTitle="添加变量"
                infoBox={{
                  __html: ` <p>
                  变量使仪表盘更具交互性和动态性。而不是像服务器这样的硬编码
                  或者传感器名称在度量查询中，可以使用变量代替它们。变量显示为
                  仪表板顶部的列表框。这些下拉列表使更改数据变得很容易
                  显示在仪表板上。查看
                    <a class="external-link" href="https://grafana.com/docs/grafana/latest/variables/" target="_blank">
                    模板和变量文档
                    </a>
                    了解更多信息。
                  </p>`,
                }}
                infoBoxTitle="变量有什么作用？"
                onClick={this.props.onAddClick}
              />
            </div>
          )}

          {this.props.variables.length > 0 && (
            <div>
              <table
                className="filter-table filter-table--hover"
                aria-label={selectors.pages.Dashboard.Settings.Variables.List.table}
              >
                <thead>
                  <tr>
                    <th>变量</th>
                    <th>定义</th>
                    <th colSpan={6} />
                  </tr>
                </thead>
                <tbody>
                  {this.props.variables.map((state, index) => {
                    const variable = state as QueryVariableModel;
                    const definition = variable.definition
                      ? variable.definition
                      : typeof variable.query === 'string'
                        ? variable.query
                        : '';
                    const usages = getVariableUsages(variable.id, this.props.usages);
                    const passed = usages > 0 || isAdHoc(variable);
                    return (
                      <tr key={`${variable.name}-${index}`}>
                        <td style={{ width: '1%' }}>
                          <span
                            onClick={(event) => this.onEditClick(event, toVariableIdentifier(variable))}
                            className="pointer template-variable"
                            aria-label={selectors.pages.Dashboard.Settings.Variables.List.tableRowNameFields(
                              variable.name
                            )}
                          >
                            {variable.name}
                          </span>
                        </td>
                        <td
                          style={{ maxWidth: '200px' }}
                          onClick={(event) => this.onEditClick(event, toVariableIdentifier(variable))}
                          className="pointer max-width"
                          aria-label={selectors.pages.Dashboard.Settings.Variables.List.tableRowDefinitionFields(
                            variable.name
                          )}
                        >
                          {definition}
                        </td>

                        <td style={{ width: '1%' }}>
                          <VariableCheckIndicator passed={passed} />
                        </td>

                        <td style={{ width: '1%' }}>
                          <VariableUsagesButton
                            id={variable.id}
                            isAdhoc={isAdHoc(variable)}
                            usages={this.props.usagesNetwork}
                          />
                        </td>

                        <td style={{ width: '1%' }}>
                          {index > 0 && (
                            <IconButton
                              onClick={(event) => this.onChangeVariableOrder(event, variable, MoveType.up)}
                              name="arrow-up"
                              title="向上移动变量"
                              aria-label={selectors.pages.Dashboard.Settings.Variables.List.tableRowArrowUpButtons(
                                variable.name
                              )}
                            />
                          )}
                        </td>

                        <td style={{ width: '1%' }}>
                          {index < this.props.variables.length - 1 && (
                            <IconButton
                              onClick={(event) => this.onChangeVariableOrder(event, variable, MoveType.down)}
                              name="arrow-down"
                              title="向下移变量"
                              aria-label={selectors.pages.Dashboard.Settings.Variables.List.tableRowArrowDownButtons(
                                variable.name
                              )}
                            />
                          )}
                        </td>

                        <td style={{ width: '1%' }}>
                          <IconButton
                            onClick={(event) => this.onDuplicateVariable(event, toVariableIdentifier(variable))}
                            name="copy"
                            title="重复变量"
                            aria-label={selectors.pages.Dashboard.Settings.Variables.List.tableRowDuplicateButtons(
                              variable.name
                            )}
                          />
                        </td>

                        <td style={{ width: '1%' }}>
                          <IconButton
                            onClick={(event) => this.onRemoveVariable(event, toVariableIdentifier(variable))}
                            name="trash-alt"
                            title="移除变量"
                            aria-label={selectors.pages.Dashboard.Settings.Variables.List.tableRowRemoveButtons(
                              variable.name
                            )}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    );
  }
}

interface VariableCheckIndicatorProps {
  passed: boolean;
}

const VariableCheckIndicator: FC<VariableCheckIndicatorProps> = ({ passed }) => {
  const style = useStyles(getStyles);
  if (passed) {
    return (
      <Icon
        name="check"
        className={style.iconPassed}
        title="此变量被其他变量或仪表板引用。"
      />
    );
  }

  return (
    <Icon
      name="exclamation-triangle"
      className={style.iconFailed}
      title="此变量未被任何变量或仪表板引用。"
    />
  );
};

const getStyles = (theme: GrafanaTheme) => ({
  iconPassed: css`
    color: ${theme.palette.greenBase};
  `,
  iconFailed: css`
    color: ${theme.palette.orange};
  `,
});
