import { SelectableValue, TimeOption } from '@grafana/data';

export const quickOptions: TimeOption[] = [
  { from: 'now-5m', to: 'now', display: '最近5分钟' },
  { from: 'now-15m', to: 'now', display: '最近15分钟' },
  { from: 'now-30m', to: 'now', display: '最近30分钟' },
  { from: 'now-1h', to: 'now', display: '最近1小时' },
  { from: 'now-3h', to: 'now', display: '最近3小时' },
  { from: 'now-6h', to: 'now', display: '最近6小时' },
  { from: 'now-12h', to: 'now', display: '最近12小时' },
  { from: 'now-24h', to: 'now', display: '最近24小时' },
  { from: 'now-2d', to: 'now', display: '最近2天' },
  { from: 'now-7d', to: 'now', display: '最近7天' },
  { from: 'now-30d', to: 'now', display: '最近30天' },
  { from: 'now-90d', to: 'now', display: '最近90天' },
  { from: 'now-6M', to: 'now', display: '最近6个月' },
  { from: 'now-1y', to: 'now', display: '最近1年' },
  { from: 'now-2y', to: 'now', display: '最近2年' },
  { from: 'now-5y', to: 'now', display: '最近5年' },
  { from: 'now-1d/d', to: 'now-1d/d', display: '昨天' },
  { from: 'now-2d/d', to: 'now-2d/d', display: '前天' },
  { from: 'now-7d/d', to: 'now-7d/d', display: '上周这一天' },
  { from: 'now-1w/w', to: 'now-1w/w', display: '上周' },
  { from: 'now-1M/M', to: 'now-1M/M', display: '上月' },
  { from: 'now-1Q/fQ', to: 'now-1Q/fQ', display: '上个月到现在' },
  { from: 'now-1y/y', to: 'now-1y/y', display: '去年' },
  { from: 'now-1y/fy', to: 'now-1y/fy', display: '去年到现在' },
  { from: 'now/d', to: 'now/d', display: '今天' },
  { from: 'now/d', to: 'now', display: '今天到现在' },
  { from: 'now/w', to: 'now/w', display: '这周' },
  { from: 'now/w', to: 'now', display: '这周到现在' },
  { from: 'now/M', to: 'now/M', display: '这个月' },
  { from: 'now/M', to: 'now', display: '这个月到现在' },
  { from: 'now/y', to: 'now/y', display: '今年' },
  { from: 'now/y', to: 'now', display: '今年到现在' },
  { from: 'now/fQ', to: 'now', display: '本财政季度到现在' },
  { from: 'now/fQ', to: 'now/fQ', display: '本财政季度' },
  { from: 'now/fy', to: 'now', display: '本财政年度到现在' },
  { from: 'now/fy', to: 'now/fy', display: '本财政年度' },
];

export const monthOptions: Array<SelectableValue<number>> = [
  { label: '一月', value: 0 },
  { label: '二月', value: 1 },
  { label: '三月', value: 2 },
  { label: '四月', value: 3 },
  { label: '五月', value: 4 },
  { label: '六月', value: 5 },
  { label: '七月', value: 6 },
  { label: '八月', value: 7 },
  { label: '九月', value: 8 },
  { label: '十月', value: 9 },
  { label: '十一月', value: 10 },
  { label: '十二月', value: 11 },
];
