import { SelectableValue } from '@grafana/data';
import {
  AxisPlacement,
  BarAlignment,
  GraphDrawStyle,
  GraphGradientMode,
  GraphTresholdsStyleMode,
  LineInterpolation,
  VisibilityMode,
  StackingMode,
} from '@grafana/schema';

/**
 * @alpha
 */
export const graphFieldOptions = {
  drawStyle: [
    { label: '线', value: GraphDrawStyle.Line },
    { label: '柱', value: GraphDrawStyle.Bars },
    { label: '点', value: GraphDrawStyle.Points },
  ] as Array<SelectableValue<GraphDrawStyle>>,

  lineInterpolation: [
    { description: '线性渐变', value: LineInterpolation.Linear, icon: 'gf-interpolation-linear' },
    { description: '平滑', value: LineInterpolation.Smooth, icon: 'gf-interpolation-smooth' },
    { description: '先走一步', value: LineInterpolation.StepBefore, icon: 'gf-interpolation-step-before' },
    { description: '追赶', value: LineInterpolation.StepAfter, icon: 'gf-interpolation-step-after' },
  ] as Array<SelectableValue<LineInterpolation>>,

  barAlignment: [
    { description: '前面', value: BarAlignment.Before, icon: 'gf-bar-alignment-before' },
    { description: '中间', value: BarAlignment.Center, icon: 'gf-bar-alignment-center' },
    { description: '后面', value: BarAlignment.After, icon: 'gf-bar-alignment-after' },
  ] as Array<SelectableValue<BarAlignment>>,

  showPoints: [
    { label: '自动', value: VisibilityMode.Auto, description: '当密度较低时显示点' },
    { label: '总是', value: VisibilityMode.Always },
    { label: '从不', value: VisibilityMode.Never },
  ] as Array<SelectableValue<VisibilityMode>>,

  axisPlacement: [
    { label: '自动', value: AxisPlacement.Auto, description: '第一个字段在左边，其他所有字段都在右边' },
    { label: '左边', value: AxisPlacement.Left },
    { label: '右边', value: AxisPlacement.Right },
    { label: '隐藏', value: AxisPlacement.Hidden },
  ] as Array<SelectableValue<AxisPlacement>>,

  fillGradient: [
    { label: '没有', value: GraphGradientMode.None },
    { label: '不透明', value: GraphGradientMode.Opacity, description: '启用填充不透明度渐变' },
    { label: '色调', value: GraphGradientMode.Hue, description: '小色调梯度' },
    {
      label: '方案',
      value: GraphGradientMode.Scheme,
      description: '使用颜色方案定义渐变',
    },
  ] as Array<SelectableValue<GraphGradientMode>>,

  stacking: [
    { label: '关闭', value: StackingMode.None },
    { label: '一般的', value: StackingMode.Normal },
    { label: '100%', value: StackingMode.Percent },
  ] as Array<SelectableValue<StackingMode>>,

  thresholdsDisplayModes: [
    { label: '关闭', value: GraphTresholdsStyleMode.Off },
    { label: '作为线条', value: GraphTresholdsStyleMode.Line },
    { label: '作为填充区域', value: GraphTresholdsStyleMode.Area },
    { label: '作为填充区域和线', value: GraphTresholdsStyleMode.LineAndArea },
  ] as Array<SelectableValue<GraphTresholdsStyleMode>>,
};
