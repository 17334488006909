import { Components } from './components';

/**
 * Selectors grouped/defined in Pages
 *
 * @alpha
 */
export const Pages = {
  Login: {
    url: '/login',
    username: '用户名输入字段',
    password: '用户名输入字段',
    submit: '登录按钮',
    skip: '跳过更改密码按钮',
  },
  Home: {
    url: '/',
  },
  DataSource: {
    name: '数据源设置页面名称输入字段',
    delete: '数据源设置页面删除按钮',
    readOnly: '数据源设置页面只读消息',
    saveAndTest: '数据源设置页面保存和测试按钮',
    alert: '数据源设置页面警报',
  },
  DataSources: {
    url: '/datasources',
    dataSources: (dataSourceName: string) => `数据源列表项 ${dataSourceName}`,
  },
  AddDataSource: {
    url: '/datasources/new',
    dataSourcePlugins: (pluginName: string) => `数据源插件项 ${pluginName}`,
  },
  ConfirmModal: {
    delete: '确认模式危险按钮',
  },
  AddDashboard: {
    url: '/dashboard/new',
    addNewPanel: '添加新面板',
    addNewRow: '新增行',
    addNewPanelLibrary: '从面板库添加新面板',
  },
  Dashboard: {
    url: (uid: string) => `/d/${uid}`,
    DashNav: {
      /**
       * @deprecated use navV2 from Grafana 8.3 instead
       */
      nav: '仪表盘导航',
      navV2: '数据测试仪表盘导航',
    },
    SubMenu: {
      submenu: '仪表盘子菜单',
      submenuItem: '数据测试模板变量',
      submenuItemLabels: (item: string) => `数据测试仪表盘模板变量子菜单标签 ${item}`,
      submenuItemValueDropDownValueLinkTexts: (item: string) =>
        `数据测试仪表盘模板变量变量值下拉列表值链接文本 ${item}`,
      submenuItemValueDropDownDropDown: '可变选项',
      submenuItemValueDropDownOptionTexts: (item: string) =>
        `数据测试仪表盘模板变量变量值下拉选项文本 ${item}`,
    },
    Settings: {
      General: {
        deleteDashBoard: '仪表盘设置页面删除仪表盘按钮',
        sectionItems: (item: string) => `仪表盘设置部分项目 ${item}`,
        saveDashBoard: '仪表盘设置和操作保存按钮',
        saveAsDashBoard: '仪表盘设置和操作另存为按钮',
        /**
         * @deprecated use components.TimeZonePicker.containerV2 from Grafana 8.3 instead
         */
        timezone: '时区选择器选择容器',
        title: '仪表盘设置页面标题',
      },
      Annotations: {
        List: {
          /**
           * @deprecated use addAnnotationCTAV2 from Grafana 8.3 instead
           */
          addAnnotationCTA: Components.CallToActionCard.button('添加注释查询'),
          addAnnotationCTAV2: Components.CallToActionCard.buttonV2('添加注释查询'),
        },
        Settings: {
          name: '注释设置名称输入',
        },
      },
      Variables: {
        List: {
          /**
           * @deprecated use addVariableCTAV2 from Grafana 8.3 instead
           */
          addVariableCTA: Components.CallToActionCard.button('增加变量'),
          addVariableCTAV2: Components.CallToActionCard.buttonV2('增加变量'),
          newButton: '变量编辑器“新建变量”按钮',
          table: '变量编辑器表',
          tableRowNameFields: (variableName: string) => `变量编辑器表 ${variableName}`,
          tableRowDefinitionFields: (variableName: string) => `变量编辑器表定义字段 ${variableName}`,
          tableRowArrowUpButtons: (variableName: string) => `变量编辑器表格箭头向上按钮 ${variableName}`,
          tableRowArrowDownButtons: (variableName: string) => `变量编辑器表格箭头向下按钮 ${variableName}`,
          tableRowDuplicateButtons: (variableName: string) => `变量编辑器表复制按钮 ${variableName}`,
          tableRowRemoveButtons: (variableName: string) => `变量编辑器表删除按钮 ${variableName}`,
        },
        Edit: {
          General: {
            headerLink: '变量编辑器标题链接',
            modeLabelNew: '变量编辑器标题模式新建',
            /**
             * @deprecated
             */
            modeLabelEdit: '变量编辑器标题模式编辑',
            generalNameInput: '变量编辑器表单名称字段',
            generalTypeSelect: '变量编辑器表单类型选择',
            generalLabelInput: '变量编辑器表单标签字段',
            generalHideSelect: '变量编辑器窗体隐藏选择',
            selectionOptionsMultiSwitch: '可变编辑器窗体多开关',
            selectionOptionsIncludeAllSwitch: '变量编辑器窗体includeal开关',
            selectionOptionsCustomAllInput: '变量编辑器表单includeal字段',
            previewOfValuesOption: '变量编辑器值预览选项',
            submitButton: '变量编辑器提交按钮',
          },
          QueryVariable: {
            queryOptionsDataSourceSelect: Components.DataSourcePicker.container,
            queryOptionsRefreshSelect: '变量编辑器表单查询刷新选择',
            queryOptionsRegExInput: '变量编辑器表单查询正则表达式字段',
            queryOptionsSortSelect: '变量编辑器表单查询排序选择',
            queryOptionsQueryInput: '变量编辑器窗体默认变量查询编辑器文本区域',
            valueGroupsTagsEnabledSwitch: '变量编辑器表单查询UseTags开关',
            valueGroupsTagsTagsQueryInput: '变量编辑器表单查询标记字段',
            valueGroupsTagsTagsValuesQueryInput: '变量编辑器表单查询标记值字段',
          },
          ConstantVariable: {
            constantOptionsQueryInput: '变量编辑器表单常量查询字段',
          },
          TextBoxVariable: {
            textBoxOptionsQueryInput: '变量编辑器表单文本框查询字段',
          },
        },
      },
    },
  },
  Dashboards: {
    url: '/dashboards',
    /**
     * @deprecated use components.Search.dashboardItem from Grafana 8.3 instead
     */
    dashboards: (title: string) => `仪表盘搜索项目 ${title}`,
  },
  SaveDashboardAsModal: {
    newName: '保存仪表盘标题字段',
    save: '保存仪表盘按钮',
  },
  SaveDashboardModal: {
    save: '仪表盘设置保存仪表盘模式保存按钮',
    saveVariables: '仪表盘设置保存仪表盘模式保存变量复选框',
    saveTimerange: '仪表盘设置保存仪表盘模式保存时间范围复选框',
  },
  SharePanelModal: {
    linkToRenderedImage: '链接到渲染图像',
  },
  Explore: {
    url: '/explore',
    General: {
      container: '数据测试探索',
      graph: 'Explore Graph',
      table: 'Explore Table',
      scrollBar: () => '.scrollbar-view',
    },
  },
  SoloPanel: {
    url: (page: string) => `/d-solo/${page}`,
  },
  PluginsList: {
    page: '插件列表页面',
    list: '插件列表',
    listItem: '插件列表项',
    signatureErrorNotice: '未签名插件通知',
  },
  PluginPage: {
    page: '插件页面',
    signatureInfo: '插件签名信息',
    disabledInfo: '插件禁用信息',
  },
  PlaylistForm: {
    name: '播放列表名称',
    interval: '播放列表间隔',
    itemRow: '播放列表项目行',
    itemIdType: '播放列表项目仪表盘（按ID类型）',
    itemTagType: '播放列表项目仪表盘（按标签类型）',
    itemMoveUp: '向上移动播放列表项目顺序',
    itemMoveDown: '向下移动播放列表项目顺序',
    itemDelete: '删除播放列表项',
  },
};
