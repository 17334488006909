import {
  BigValueColorMode,
  BigValueTextMode,
  commonOptionsBuilder,
  sharedSingleStatMigrationHandler,
} from '@grafana/ui';
import { PanelPlugin } from '@grafana/data';
import { addOrientationOption, addStandardDataReduceOptions, StatPanelOptions } from './types';
import { StatPanel } from './StatPanel';
import { statPanelChangedHandler } from './StatMigrations';
import { StatSuggestionsSupplier } from './suggestions';

export const plugin = new PanelPlugin<StatPanelOptions>(StatPanel)
  .useFieldConfig()
  .setPanelOptions((builder) => {
    const mainCategory = ['统计样式'];

    addStandardDataReduceOptions(builder);
    addOrientationOption(builder, mainCategory);
    commonOptionsBuilder.addTextSizeOptions(builder);

    builder.addSelect({
      path: 'textMode',
      name: '文本模式',
      description: '控制是否显示名称和值或仅显示名称',
      category: mainCategory,
      settings: {
        options: [
          { value: BigValueTextMode.Auto, label: '自动' },
          { value: BigValueTextMode.Value, label: '值' },
          { value: BigValueTextMode.ValueAndName, label: '值和名称' },
          { value: BigValueTextMode.Name, label: '名称' },
          { value: BigValueTextMode.None, label: '不显示' },
        ],
      },
      defaultValue: 'auto',
    });

    builder
      .addRadio({
        path: 'colorMode',
        name: '颜色模式',
        defaultValue: BigValueColorMode.Value,
        category: mainCategory,
        settings: {
          options: [
            { value: BigValueColorMode.None, label: '不显示' },
            { value: BigValueColorMode.Value, label: '值' },
            { value: BigValueColorMode.Background, label: '背景' },
          ],
        },
      })
      .addRadio({
        path: 'graphMode',
        name: '图形模式',
        description: '统计面板图/迷你图模式',
        category: mainCategory,
        defaultValue: 'area',
        settings: {
          options: [
            { value: 'none', label: '不显示' },
            { value: 'area', label: '区域' },
          ],
        },
      })
      .addRadio({
        path: 'justifyMode',
        name: '文本对齐',
        defaultValue: 'auto',
        category: mainCategory,
        settings: {
          options: [
            { value: 'auto', label: '自动' },
            { value: 'center', label: '居中' },
          ],
        },
      });
  })
  .setNoPadding()
  .setPanelChangeHandler(statPanelChangedHandler)
  .setSuggestionsSupplier(new StatSuggestionsSupplier())
  .setMigrationHandler(sharedSingleStatMigrationHandler);
