import React from 'react';
import { DataTransformerID, standardTransformers, TransformerRegistryItem, TransformerUIProps } from '@grafana/data';
import { MergeTransformerOptions } from '../../../../../packages/grafana-data/src/transformations/transformers/merge';
import { FieldValidationMessage } from '@grafana/ui';

export const MergeTransformerEditor: React.FC<TransformerUIProps<MergeTransformerOptions>> = ({
  input,
  options,
  onChange,
}) => {
  if (input.length <= 1) {
    // Show warning that merge is useless only apply on a single frame
    return <FieldValidationMessage>在单个帧上应用“合并”时没有效果。</FieldValidationMessage>;
  }
  return null;
};

export const mergeTransformerRegistryItem: TransformerRegistryItem<MergeTransformerOptions> = {
  id: DataTransformerID.merge,
  editor: MergeTransformerEditor,
  transformation: standardTransformers.mergeTransformer,
  name: '合并',
  description: `合并多个系列/表格并返回一个表格，其中可合并的值将合并到同一行中。
  用于在一个表中显示多个系列、表格或两者的组合。`,
};
