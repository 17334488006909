import {
  FieldOverrideContext,
  FieldType,
  getFieldDisplayName,
  PanelPlugin,
  ReducerID,
  standardEditorsRegistry,
} from '@grafana/data';
import { TablePanel } from './TablePanel';
import { PanelOptions, defaultPanelOptions, defaultPanelFieldConfig } from './models.gen';
import { TableFieldOptions } from '@grafana/schema';
import { tableMigrationHandler, tablePanelChangedHandler } from './migrations';
import { TableCellDisplayMode } from '@grafana/ui';
import { TableSuggestionsSupplier } from './suggestions';

export const plugin = new PanelPlugin<PanelOptions, TableFieldOptions>(TablePanel)
  .setPanelChangeHandler(tablePanelChangedHandler)
  .setMigrationHandler(tableMigrationHandler)
  .setNoPadding()
  .useFieldConfig({
    useCustomConfig: (builder) => {
      builder
        .addNumberInput({
          path: 'minWidth',
          name: '最小列宽',
          description: '自动调整列大小的最小宽度',
          settings: {
            placeholder: '150',
            min: 50,
            max: 500,
          },
          shouldApply: () => true,
          defaultValue: defaultPanelFieldConfig.minWidth,
        })
        .addNumberInput({
          path: 'width',
          name: '列宽',
          settings: {
            placeholder: 'auto',
            min: 20,
            max: 300,
          },
          shouldApply: () => true,
          defaultValue: defaultPanelFieldConfig.width,
        })
        .addRadio({
          path: 'align',
          name: '列对齐',
          settings: {
            options: [
              { label: 'auto', value: '自动' },
              { label: 'left', value: '左' },
              { label: 'center', value: '中' },
              { label: 'right', value: '右' },
            ],
          },
          defaultValue: defaultPanelFieldConfig.align,
        })
        .addSelect({
          path: 'displayMode',
          name: '单元显示模式',
          description: '彩色文本、背景、显示为仪表等',
          settings: {
            options: [
              { value: TableCellDisplayMode.Auto, label: '自动' },
              { value: TableCellDisplayMode.ColorText, label: '彩色文字' },
              { value: TableCellDisplayMode.ColorBackground, label: '颜色背景（渐变）' },
              { value: TableCellDisplayMode.ColorBackgroundSolid, label: '彩色背景（纯色）' },
              { value: TableCellDisplayMode.GradientGauge, label: '梯度规' },
              { value: TableCellDisplayMode.LcdGauge, label: '液晶仪表' },
              { value: TableCellDisplayMode.BasicGauge, label: '基本量规' },
              { value: TableCellDisplayMode.JSONView, label: 'JSON 视图' },
              { value: TableCellDisplayMode.Image, label: '图片' },
            ],
          },
          defaultValue: defaultPanelFieldConfig.displayMode,
        })
        .addBooleanSwitch({
          path: 'filterable',
          name: '列过滤器',
          description: '启用/禁用表中的字段过滤器',
          defaultValue: defaultPanelFieldConfig.filterable,
        })
        .addBooleanSwitch({
          path: 'hidden',
          name: '隐藏在表格中',
          defaultValue: undefined,
          hideFromDefaults: true,
        });
    },
  })
  .setPanelOptions((builder) => {
    builder
      .addBooleanSwitch({
        path: 'showHeader',
        name: '显示标题',
        description: "显示或不显示表头",
        defaultValue: defaultPanelOptions.showHeader,
      })
      .addBooleanSwitch({
        path: 'footer.show',
        name: '显示页脚',
        description: "显示或不显示表格页脚",
        defaultValue: defaultPanelOptions.footer?.show,
      })
      .addCustomEditor({
        id: 'footer.reducer',
        path: 'footer.reducer',
        name: '计算',
        description: '选择reducer函数/计算',
        editor: standardEditorsRegistry.get('stats-picker').editor as any,
        defaultValue: [ReducerID.sum],
        showIf: (cfg) => cfg.footer?.show,
      })
      .addMultiSelect({
        path: 'footer.fields',
        name: '字段',
        description: '选择应计算的字段',
        settings: {
          allowCustomValue: false,
          options: [],
          placeholder: '所有数值字段',
          getOptions: async (context: FieldOverrideContext) => {
            const options = [];
            if (context && context.data && context.data.length > 0) {
              const frame = context.data[0];
              for (const field of frame.fields) {
                if (field.type === FieldType.number) {
                  const name = getFieldDisplayName(field, frame, context.data);
                  const value = field.name;
                  options.push({ value, label: name } as any);
                }
              }
            }
            return options;
          },
        },
        defaultValue: '',
        showIf: (cfg) => cfg.footer?.show,
      });
  })
  .setSuggestionsSupplier(new TableSuggestionsSupplier());
