import { OptionsWithTooltip } from '@grafana/schema';
import { PanelOptionsEditorBuilder } from '@grafana/data';

export function addTooltipOptions<T extends OptionsWithTooltip>(
  builder: PanelOptionsEditorBuilder<T>,
  singleOnly = false
) {
  const options = singleOnly
    ? [
        { value: 'single', label: '单独' },
        { value: 'none', label: '隐藏' },
      ]
    : [
        { value: 'single', label: '单独' },
        { value: 'multi', label: '全部' },
        { value: 'none', label: '隐藏' },
      ];

  builder.addRadio({
    path: 'tooltip.mode',
    name: '工具提示模式',
    category: ['工具提示'],
    description: '',
    defaultValue: 'single',
    settings: {
      options,
    },
  });
}
