import React from 'react';

const PermissionsInfo = () => (
  <div>
    <h5>什么是权限？</h5>
    <p>
      访问控制列表 (ACL) 模型用于限制对仪表板文件夹的访问。 可以为用户或团队分配文件夹或单个仪表板的权限。
    </p>
  </div>
);

export default PermissionsInfo;
