import React, { PureComponent } from 'react';
import { Modal, stylesFactory } from '@grafana/ui';
import { css } from '@emotion/css';
import { dashboardWatcher } from './dashboardWatcher';
import { config } from '@grafana/runtime';
import { DashboardEvent, DashboardEventAction } from './types';
import { GrafanaTheme } from '@grafana/data';

interface Props {
  event?: DashboardEvent;
}

interface State {
  dismiss?: boolean;
}

interface ActionInfo {
  label: string;
  description: string;
  action: () => void;
}

export class DashboardChangedModal extends PureComponent<Props, State> {
  state: State = {};

  discardAndReload: ActionInfo = {
    label: '放弃本地更改',
    description: '加载此仪表盘的最新保存版本',
    action: () => {
      dashboardWatcher.reloadPage();
      this.onDismiss();
    },
  };

  continueEditing: ActionInfo = {
    label: '继续编辑',
    description:
      '保留本地更改并继续编辑。注意：保存时，这将覆盖最近的更改',
    action: () => {
      this.onDismiss();
    },
  };

  acceptDelete: ActionInfo = {
    label: '放弃本地更改',
    description: '查看grafana主页',
    action: () => {
      // Navigate to the root URL
      document.location.href = config.appUrl;
    },
  };

  onDismiss = () => {
    this.setState({ dismiss: true });
  };

  render() {
    const { event } = this.props;
    const { dismiss } = this.state;
    const styles = getStyles(config.theme);

    const isDelete = event?.action === DashboardEventAction.Deleted;

    const options = isDelete
      ? [this.continueEditing, this.acceptDelete]
      : [this.continueEditing, this.discardAndReload];

    return (
      <Modal
        isOpen={!dismiss}
        title="仪表盘已更改"
        icon="copy"
        onDismiss={this.onDismiss}
        onClickBackdrop={() => {}}
        className={styles.modal}
      >
        <div>
          {isDelete ? (
            <div>此仪表盘已被另一个会话删除</div>
          ) : (
            <div>此仪表盘已被另一个会话修改</div>
          )}
          <br />
          {options.map((opt) => {
            return (
              <div key={opt.label} onClick={opt.action} className={styles.radioItem}>
                <h3>{opt.label}</h3>
                {opt.description}
              </div>
            );
          })}
          <br />
        </div>
      </Modal>
    );
  }
}

const getStyles = stylesFactory((theme: GrafanaTheme) => {
  return {
    modal: css`
      width: 500px;
    `,
    radioItem: css`
      margin: 0;
      font-size: ${theme.typography.size.sm};
      color: ${theme.colors.textWeak};
      padding: 10px;
      cursor: pointer;
      width: 100%;

      &:hover {
        background: ${theme.colors.bgBlue1};
        color: ${theme.colors.text};
      }
    `,
  };
});
